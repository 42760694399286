import React, { useEffect, useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import {Layout} from '../components/layout/layout'
import Seo from '../components/seo/seo'
import { RaisingBox } from '../components/raisingBox/raisingBox'
import { SsyIntersectionObserver } from '../components/ssyIntersectionObserver/ssyIntersectionObserver'
import ReactPlayer from 'react-player'
import heartIcon from '../assets/images/heart.svg'

import style from '../assets/css/showPost.module.css'

const ShowPostPage = (props) => {
  const intl = useIntl()
  const [postData, setPostData] = useState(null)

  useEffect(() => {
    let isSubcribed = true
    const urlParams = new URLSearchParams(window.location.search)
    const postId = urlParams.get('postId')
    fetch(
      `${process.env.GATSBY_API_URL}/posts/shared/${postId}?api-version=2.1`,
      {
        headers: {
          'api-version': '2.1',
        },
      }
    )
      .then(response => response.json())
      .then((res) => {
        if (isSubcribed) {
          setPostData({
            likesCount: res.likesCount,
            resource_uri: res.resource_uri,
            height: res.height,
            width: res.width,
            type: res.type,
          })
        }
      })
      .catch(e => console.log(e))

    return () => {
      isSubcribed = false
    }
  }, [])

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'HOME_META_DESCRIPTION' })}
        lang={intl.formatMessage({ id: 'MAIN_LANGUAGE' })}
        url={props.location.pathname}
        description={intl.formatMessage({ id: 'HOME_META_DESCRIPTION' })}
      />
      {!!postData && (
        <section className={style.mainSection}>
          <SsyIntersectionObserver>
            <RaisingBox>
              {postData.type === 2 ? (
                <ReactPlayer
                  controls={true}
                  width="100%"
                  height={postData.height >= postData.width ? '75vh' : '100%'}
                  url={postData.resource_uri}
                  playing={true}
                />
              ) : (
                <img
                  style={{ maxWidth: '100%' }}
                  src={postData.resource_uri}
                  alt="post"
                />
              )}

              <div style={{ fontSize: '20px' }} className={style.marginM}>
              <img src={heartIcon} alt="heart"/>

                <span>{postData.likesCount}</span>
              </div>
            </RaisingBox>
          </SsyIntersectionObserver>
          <div className={style.marginM} />
        </section>
      )}
    </Layout>
  )
}

export default ShowPostPage
